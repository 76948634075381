import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import { Main } from "../components/Merry/Layout"
import PageWrap from "../components/PageWrap"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    vea: {
      childImageSharp: {
        fluid: any
      }
    }
    alice: {
      childImageSharp: {
        fluid: any
      }
    }
  }
}

const AboutPage = ({ data }: Props) => {
  return (
    <PageWrap title="About" description="" navOpen={true}>
      <Main>
        <h1>About</h1>
        <p>
          I am an experience software engineer living in Tokyo, specializing in
          producing scalable systems and APIs.
        </p>

        <p>email: mail[at]galaco.me</p>

        <p>
          I like to write game tools (particularly for Source Engine related
          activities) for fun.
        </p>

        <h3>Misc.</h3>
        <p>
          I also collect mechanical keyboards. It’s expensive stuff, but the
          pursuit of perfection is never cheap, right? No-one bats an eye at a
          mechanic spending 1000’s on a great toolset, so why should a
          programmer be any different?
        </p>

        <p>
          My first truly custom board{" "}
          <b>(Oddforge VE.A | GMK Hyperfuse Origins | Zealio 65g switches)</b>
        </p>
        <Image
          fluid={data.vea.childImageSharp.fluid}
          alt="Oddforge VE.A | GMK Hyperfuse Origins | Zealio 65g switches"
        />
        <p>
          The endgame <b>(TGR Alice | GMK Laser | C3 Tangerine switches)</b>
        </p>
        <Image
          fluid={data.alice.childImageSharp.fluid}
          alt="TGR Alice | GMK Laser | C3 Tangerine switches"
        />
      </Main>
    </PageWrap>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    vea: file(absolutePath: { regex: "/ve.a_hyperfuse.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alice: file(absolutePath: { regex: "/tgr-alice_laser.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
