import React from "react"
import styled from "styled-components"
import { rhythm, SizeMD, SizeSM } from "./vars"

import "./css/codeblock.css"
import "./css/videoembed.css"

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`

const MainWrapper = styled.main`
  padding: ${rhythm(1.5)};
  padding-bottom: 0;
  width: 100%;
  margin-left: 0;

  @media screen and (min-width: ${SizeMD}) {
    padding-bottom: 0;
  }
`

const MainInner = styled.div`
  max-width: 900px;
  margin: 0 auto;

  @media screen and (min-width: ${SizeMD}) {
    width: calc(100% - 300px);
  }
`

interface MainProps {
  children?: any
}

export const Main = ({ children }: MainProps) => (
  <MainWrapper>
    <MainInner>{children}</MainInner>
  </MainWrapper>
)
